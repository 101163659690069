import React, { useState, useEffect } from 'react'
import { Card, Alert } from 'react-bootstrap'
import { useAuth, db } from '../../contexts/AuthContext'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar'
import { getDoc, doc } from 'firebase/firestore';

const Profile = () => {
    const { currentUser } = useAuth();

    const [name, setName] = useState([]);
    const [email, setEmail] = useState([]);
    const [role, setRole] = useState([]);

    const [error, setError] = useState('');

    // async function handleSignOut() {
    //     setError('')

    //     try {
    //         await logout()
    //         navigate('/login')
    //     } catch (err) {
    //         setError('Failed to sign out, reason: "' + err.message + '"')
    //     }
    // }

    const getProfileData = async (userId) => {
        try {
            setError('');
            const userSnapshot = await getDoc(doc(db, "users", userId));

            setName(userSnapshot.data().name);
            setEmail(userSnapshot.data().email);
            setRole(userSnapshot.data().role);
        } catch (err) {
            setError(err.message);
        }
    };

    useEffect(() => {
        if (currentUser.uid !== undefined && currentUser.uid !== '') {
            getProfileData(currentUser.uid);
        }
    }, [currentUser.uid]);


    return (
        <div className='profile'>
            <Sidebar />
            <div className='profileContainer d-flex align-items-center justify-content-center mt-10'>
                <div className='w-100'>
                    <Card>
                        <Card.Body>
                            <h2 className='mb-4'>My Profile</h2>
                            {error && <Alert variant='danger'>{error}</Alert>}
                            <div className='strong'>
                                Name
                            </div>
                            <div className='content'>
                                {name}
                            </div>
                            <div className='strong'>
                                Email
                            </div>
                            <div className='content'>
                                {email}
                            </div>
                            <div className='strong'>
                                Role
                            </div>
                            <div className='content'>
                                {role}
                            </div>
                            <div className='actionBtns'>
                                <Link to='/profile/updateProfile' state={{ uid: currentUser.uid }} style={{ textDecoration: 'none' }}>
                                    <div className='updateButton'>Update Profile</div>
                                </Link>
                            </div>
                            {/* <strong>Role: </strong>{userInfo && userInfo.role} <br /> */}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default Profile