import React from 'react'
import Sidebar from './Sidebar'
import Widget from './Widget'

const Dashboard = () => {

  return (
    <>
      <section id='dashboard'>
        <Sidebar />
        <div className='container-fluid dashboard'>
          <div className='widgets'>
            <Widget type="highlight" />
            <Widget type="publication" />
            <Widget type="members" />
            <Widget type="jobs" />
            <Widget type="queries" />
          </div>
          {/* <div className='charts'>
            <FeaturedChart />
          </div> */}
        </div>
      </section>
    </>
  )
}

export default Dashboard