import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../Sidebar'
import { Alert } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../contexts/AuthContext';

const EditPublication = () => {
    const location = useLocation();
    const publicationId = location.state.id;

    const [error, setError] = useState([]);

    const [title, setTitle] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [publicationDate, setPublicationDate] = useState([]);
    const [journal, setJournal] = useState([]);
    const [abstract, setAbstract] = useState([]);
    const [link, setLink] = useState([]);

    const publicationRef = useRef('');
    const authorsRef = useRef('');
    const publicationDateRef = useRef('');
    const journalRef = useRef('');
    const abstractRef = useRef('');
    const manuscriptLinkRef = useRef('');

    const navigate = useNavigate();

    // Update the database with new details from the form
    const editPublication = async (e) => {
        e.preventDefault();

        setError('');

        try {
            if (publicationId !== undefined && publicationId !== '') {
                // If a publication exists, update the details
                const publicationTitle = publicationRef.current.value;
                const authors = authorsRef.current.value;
                const publicationDate = publicationDateRef.current.value;
                const journal = journalRef.current.value;
                const abstract = abstractRef.current.value;
                const manuscriptLink = manuscriptLinkRef.current.value;

                const newPublication = { publicationTitle, authors, publicationDate, journal, abstract, manuscriptLink };

                const publicationDoc = doc(db, "publications", publicationId);
                await updateDoc(publicationDoc, newPublication);

                navigate('/allPublications');
            }
        } catch (err) {
            setError('Failed to update the publication, reason: "' + err.message + '"');
        }
    };

    // Populate the current details of publication in the form
    const editPublicationHandler = async () => {
        try {
            setError('');

            const publicationSnapshot = await getDoc(doc(db, "publications", publicationId));

            setTitle(publicationSnapshot.data().publicationTitle);
            setAuthors(publicationSnapshot.data().authors);
            setPublicationDate(publicationSnapshot.data().publicationDate);
            setJournal(publicationSnapshot.data().journal);
            setAbstract(publicationSnapshot.data().abstract);
            setLink(publicationSnapshot.data().manuscriptLink);
        } catch (err) {
            setError(err.message)
        }
    };

    useEffect(() => {
        if (publicationId !== undefined && publicationId !== '') {
            editPublicationHandler();
        }
    }, [publicationId]);

    return (
        <div className='editPublication'>
            <Sidebar />
            <div className='editPublicationContainer'>
                <h2>Update Publication</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                <form onSubmit={editPublication}>
                    <div className='formInput'>
                        <label>Publication Title</label>
                        <input placeholder='Publication' ref={publicationRef} required defaultValue={title} />
                    </div>
                    <div className='formInput'>
                        <label>Authors</label>
                        <input placeholder='Author names' ref={authorsRef} required defaultValue={authors} />
                    </div>
                    <div className='formInput'>
                        <label>Publication date</label>
                        <input type='date' placeholder='Publication date' ref={publicationDateRef} required defaultValue={publicationDate}></input>
                    </div>
                    <div className='formInput'>
                        <label>Journal/ Publisher</label>
                        <input type='text' placeholder='Journal Name, Publisher' ref={journalRef} required defaultValue={journal}></input>
                    </div>
                    <div className='formInput'>
                        <label>Abstract</label>
                        <textarea type='text' placeholder='Abstract' ref={abstractRef} required rows='8' defaultValue={abstract}></textarea>
                    </div>
                    <div className='formInput'>
                        <label>PDF Link</label>
                        <input type='text' placeholder='Link to the official PDF version' ref={manuscriptLinkRef} required defaultValue={link}></input>
                    </div>
                    <button type='submit'>Update Publication</button>
                </form>
            </div>
        </div>
    )
}

export default EditPublication