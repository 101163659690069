import React, { useEffect, useState } from 'react'
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../contexts/AuthContext';
import StarsIcon from '@mui/icons-material/Stars';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';

const Widgets = ({ type }) => {

  let data;

  const [hSize, setHighlightSize] = useState(null);
  const [pSize, setPublicationSize] = useState(null);
  const [tSize, setTeamSize] = useState(null);
  const [jSize, setJobSize] = useState(null);
  const [qSize, setQuerySize] = useState(null);

  switch (type) {
    case "highlight":
      data = {
        title: "Highlights",
        link: "See all highlights",
        icon: (<StarsIcon className='materialIcon' style={{ color: "#f7a916" }} />),
        count: hSize,
        path: '/allHighlights'
      }
      break;
    case "publication":
      data = {
        title: "Publications",
        link: "See all publications",
        icon: (<HistoryEduIcon className='materialIcon' style={{ color: "#f2c372" }} />),
        count: pSize,
        path: '/allPublications'
      }
      break;
    case "members":
      data = {
        title: "Members",
        link: "See all members",
        icon: (<PeopleAltIcon className='materialIcon' style={{ color: "#6bc9e8" }} />),
        count: tSize,
        path: '/allMembers'
      }
      break;
    case "jobs":
      data = {
        title: "Jobs",
        link: "See all jobs",
        icon: (<WorkIcon className='materialIcon' style={{ color: "#798fb8" }} />),
        count: jSize,
        path: '/allJobs'
      }
      break;
    case "queries":
      data = {
        title: "Queries",
        link: "See all queries",
        icon: (<EmailIcon className='materialIcon' style={{ color: "#885db0" }} />),
        count: qSize,
        path: '/allQueries'
      }
      break;
    default:
      break;
  }

  useEffect(() => {
    const getHighlightSize = async () => {
      const hSize = await getDocs(query(collection(db, "highlights")));
      setHighlightSize(hSize.docs.length);
    };

    const getPublicationSize = async () => {
      const pSize = await getDocs(query(collection(db, "publications")));
      setPublicationSize(pSize.docs.length);
    };

    const getMemberSize = async () => {
      const mSize = await getDocs(query(collection(db, "members")));
      setTeamSize(mSize.docs.length);
    };

    const getJobSize = async () => {
      const jSize = await getDocs(query(collection(db, "jobs")));
      setJobSize(jSize.docs.length);
    };

    const getQuerySize = async () => {
      const qSize = await getDocs(query(collection(db, "queries")));
      setQuerySize(qSize.docs.length);
    };

    getHighlightSize();
    getPublicationSize();
    getMemberSize();
    getJobSize();
    getQuerySize();
  })

  return (
    <div className='widget'>
      <div className='left'>
        <span className='title'>{data.title}</span>
        <span className='counter'>{data.count}</span>
        <Link to={data.path}><span className='link'>{data.link}</span></Link>
      </div>
      <div className='right'>
        {data.icon}
      </div>
    </div>
  )
}

export default Widgets