import React from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard';
import StarsIcon from '@mui/icons-material/Stars';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LogoutIcon from '@mui/icons-material/Logout';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Sidebar = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    async function handleSignout() {
        await logout();
        navigate('/login');
    }

    return (
        <div className='sidebar'>
            <div className='top'>
                <span className='logo'>
                    <NavLink to='/dashboard' style={{ textDecoration: "none", color: "#fff" }}>
                        DaTALab
                    </NavLink>
                </span>
            </div>
            <hr />
            <div className='center'>
                <ul>
                    <NavLink to='/dashboard'>
                        <li>
                            <DashboardIcon className='materialIcon' />
                            Dashboard
                        </li>
                    </NavLink>
                    <NavLink to='/allHighlights'>
                        <li>
                            <StarsIcon className='materialIcon' />
                            Highlights
                        </li>
                    </NavLink>
                    <NavLink to='/allPublications'>
                        <li>
                            <HistoryEduIcon className='materialIcon' />
                            <span>Publications</span>
                        </li>
                    </NavLink>
                    <NavLink to='/allMembers'>
                        <li>
                            <PeopleAltIcon className='materialIcon' />
                            <span>Team Members</span>
                        </li>
                    </NavLink>
                    <NavLink to='/allJobs'>
                        <li>
                            <WorkIcon className='materialIcon' />
                            <span>Jobs</span>
                        </li>
                    </NavLink>
                    <NavLink to='/allQueries'>
                        <li>
                            <EmailIcon className='materialIcon' />
                            <span>Queries received</span>
                        </li>
                    </NavLink>
                    <NavLink to='/allUsers'>
                        <li>
                            <PeopleIcon className='materialIcon' />
                            <span>All users</span>
                        </li>
                    </NavLink>
                    <NavLink to='/profile'>
                        <li>
                            <AssignmentIndIcon className='materialIcon' />
                            <span>My profile</span>
                        </li>
                    </NavLink>
                    <li onClick={() => handleSignout()}>
                        <LogoutIcon className='materialIcon' />
                        <span>Sign out</span>
                    </li>
                </ul>
            </div>
            <div className='bottom'>
                Developed by <a href="https://manmeetkaurbaxi.com" target="_blank"> Manmeet Kaur Baxi</a>
            </div>
        </div>
    )
}

export default Sidebar