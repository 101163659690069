import React from 'react'
import AllMembers from './AllMembers'
import Sidebar from '../Sidebar'

const Members = () => {
    return (
        <div className='members'>
            <Sidebar />
            <AllMembers />
        </div>
    )
}

export default Members