import React, { useState, useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { db } from '../../contexts/AuthContext';
import { collection, deleteDoc, onSnapshot, doc } from 'firebase/firestore';

const columns: GridColDef[] = [
    { field: 'publicationTitle', headerName: 'Publication title', flex: 0.65 },
    { field: 'authors', headerName: 'Authors', flex: 0.45 },
    { field: 'publicationDate', headerName: 'Publication date', flex: 0.25 },
    { field: 'journal', headerName: 'Journal, Publisher', flex: 0.35 },
];

const AllPublications = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        // Fetch data real-time
        const fetchData = onSnapshot(collection(db, "publications"), (snapshot) => {
            setError('');
            let dataList = [];
            snapshot.docs.forEach((doc) => {
                dataList.push({ id: doc.id, ...doc.data() });
            });
            setData(dataList);
        },
            (error) => {
                setError(error);
            });

        return () => {
            fetchData();
        };
    }, [])

    const deletePublication = async (id) => {
        try {
            setError('');
            await deleteDoc(doc(db, "publications", id));
            setData(data.filter((item) => item.id !== id));
            navigate('/allPublications');
        } catch (err) {
            setError(err);
        }
    };

    const actionColumn = [{
        field: "action", headerName: "Action", flex: 0.5,
        renderCell: (params) => {
            const pubId = params.row.id;
            return (
                <div className='cellAction'>
                    <Link to='/allPublications/viewPublication' state={{ id: pubId }} style={{ textDecoration: "none" }}>
                        <div className='viewButton'>View details</div>
                    </Link>
                    <Link to={{ pathname: '/allPublications/editPublication' }} state={{ id: pubId }} style={{ textDecoration: "none" }}>
                        <div className='updateButton'>Update</div>
                    </Link>
                    <Link to='/' style={{ textDecoration: "none" }}>
                        <div className='deleteButton'
                            onClick={() => deletePublication(pubId)}>
                            Delete
                        </div>
                    </Link>
                </div >
            )
        }
    }]

    return (
        <div className='allPublications'>
            <div className='publicationsTable'>
                <div className='publicationsTitle'>
                    All Publications
                    <Link to='/allPublications/addPublication' className='addPublicationBtn'>
                        <AddIcon className='materialIcon'></AddIcon>
                        Add new publication
                    </Link>
                    {error && <Alert variant='danger'>{error}</Alert>}
                </div>
                <DataGrid autoHeight
                    getRowHeight={() => 'auto'}
                    rows={data}
                    columns={columns.concat(actionColumn)}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'publicationDate', sort: 'desc' }],
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default AllPublications