import React, { useState, useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { db } from '../../contexts/AuthContext';
import { collection, deleteDoc, onSnapshot, doc } from 'firebase/firestore';
import { Alert } from 'react-bootstrap'

const columns: GridColDef[] = [
    { field: 'highlight', headerName: 'Highlight', flex: 1.5 },
    { field: 'highlightDate', headerName: 'Date Added', flex: 0.5 },
    { field: 'highlightLink', headerName: 'URL', flex: 1 },
];

const AllHighlights = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = onSnapshot(collection(db, "highlights"), (snapshot) => {
            setError('');
            let dataList = [];
            snapshot.docs.forEach((doc) => {
                dataList.push({ id: doc.id, ...doc.data() });
            });
            setData(dataList);
        },
            (error) => {
                setError(error);
            });

        return () => {
            fetchData();
        };
    }, [])

    const deleteHighlight = async (id) => {
        try {
            setError('');
            await deleteDoc(doc(db, "highlights", id));
            setData(data.filter((item) => item.id !== id));
            navigate('/allHighlights');
        } catch (err) {
            setError(err);
        }
    };

    const actionColumn = [{
        field: "action", headerName: "Action", flex: 0.5,
        renderCell: (params) => {
            const highlightId = params.row.id;
            // console.log(highlightId);
            return (
                <div className='cellAction'>
                    {/* <Link to='/allHighlights/:highlightId' style={{ textDecoration: "none" }}>
                        <div className='viewButton'>View</div>
                    </Link> */}
                    <Link to={{ pathname: '/allHighlights/editHighlight' }} state={{ id: highlightId }} style={{ textDecoration: "none" }}>
                        <div className='updateButton'>Update</div>
                    </Link>
                    <Link to='/' style={{ textDecoration: "none" }}>
                        <div className='deleteButton'
                            onClick={() => deleteHighlight(params.row.id)}>
                            Delete
                        </div>
                    </Link>
                </div >
            )
        }
    }]

    return (
        <div className='allHighlights'>
            <div className='highlightTable'>
                <div className='highlightsTitle'>
                    All Highlights
                    <Link to='/allHighlights/addHighlight' className='addHighlightBtn'>
                        <AddIcon className='materialIcon'></AddIcon>
                        Add new highlight
                    </Link>
                    {error && <Alert variant='danger'>{error}</Alert>}
                </div>
                <DataGrid autoHeight
                    getRowHeight={() => 'auto'}
                    rows={data}
                    columns={columns.concat(actionColumn)}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'highlightDate', sort: 'desc' }],
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default AllHighlights