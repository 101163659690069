import React, { useState, useRef, useEffect } from 'react'
import { Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import Sidebar from '../Sidebar'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../contexts/AuthContext';

const todayDate = new Date();
const todayLocalDate = (new Date(todayDate.getTime() - todayDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

const AddJob = () => {
  const jobTitleRef = useRef('');
  const jobLocationRef = useRef('');
  const jobResponsibilitiesRef = useRef('');
  const qualificationsRef = useRef('');
  const startDateRef = useRef('');
  const jobDurationRef = useRef('');
  const applicationProcessRef = useRef('');
  const applicationDeadlineRef = useRef('');
  const payRangeRef = useRef('');

  const [error, setError] = useState(null);

  const navigate = useNavigate();

  async function addJob(e) {
    e.preventDefault();

    try {
      setError('');
      const jobTitle = jobTitleRef.current.value;
      const jobLocation = jobLocationRef.current.value;
      const jobResponsibilities = jobResponsibilitiesRef.current.value;
      const qualifications = qualificationsRef.current.value;
      const startDate = startDateRef.current.value;
      const jobDuration = jobDurationRef.current.value;
      const applicationDeadline = applicationDeadlineRef.current.value;
      const applicationProcess = applicationProcessRef.current.value;
      const payRange = payRangeRef.current.value;

      await addDoc(collection(db, "jobs"), {
        "jobTitle": jobTitle,
        "jobLocation": jobLocation,
        "jobResponsibilities": jobResponsibilities,
        "qualifications": qualifications,
        "applicationDeadline": applicationDeadline,
        "startDate": startDate,
        "jobDuration": jobDuration,
        "payRange": payRange,
        "applicationProcess": applicationProcess,
        "serverTimestamp": serverTimestamp()
      });

      navigate('/allJobs');
    } catch (err) {
      setError('Failed to add the job, reason: "' + err.message + '"')
    }
  }

  return (
    <div className='addJob'>
      <Sidebar />
      <div className='addJobContainer'>
        <h2>Add Job</h2>
        {error && <Alert variant='danger'>{error}</Alert>}
        <form onSubmit={addJob}>
          <div className='formInput'>
            <label>Job Title</label>
            <input type='text' placeholder='Research Assistant' ref={jobTitleRef} required />
          </div>
          <div className='formInput'>
            <label>Location</label>
            <input type='text' placeholder='DaTALab, York University' ref={jobLocationRef} required></input>
          </div>
          <div className='formInput'>
            <label>Job Responsibilities</label>
            <textarea placeholder='...' ref={jobResponsibilitiesRef} required rows="8"></textarea>
          </div>
          <div className='formInput'>
            <label>Qualifications</label>
            <textarea placeholder='...' ref={qualificationsRef} required rows="8"></textarea>
          </div>
          <div className='formInput'>
            <label>Application Deadline</label>
            <input type='date' placeholder='Date' min={todayLocalDate} ref={applicationDeadlineRef} required></input>
          </div>
          <div className='formInput'>
            <label>Tentative Start Date</label>
            <input type='date' placeholder='Date' min={todayLocalDate} ref={startDateRef} required></input>
          </div>
          <div className='formInput'>
            <label>Job Duration</label>
            <input type='text' placeholder='3 months' ref={jobDurationRef} required></input>
          </div>
          <div className='formInput'>
            <label>Hourly Pay Range</label>
            <input type='number' placeholder='C$35' ref={payRangeRef} ></input>
          </div>
          <div className='formInput'>
            <label>Application Process</label>
            <input type='text' placeholder='Email your CV to vmago@lakeheadu.ca' ref={applicationProcessRef} required></input>
          </div>
          <button type='submit'>Add Job</button>
        </form>
      </div>
    </div>
  )
}

export default AddJob