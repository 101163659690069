import React, { useState, useRef } from 'react'
import { Alert } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

// const todayDate = new Date();
// const todayLocalDate = (new Date(todayDate.getTime() - todayDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

const AddPublication = () => {
    const publicationRef = useRef('');
    const authorsRef = useRef('');
    const publicationDateRef = useRef('');
    const journalRef = useRef('');
    const abstractRef = useRef('');
    const manuscriptLinkRef = useRef('');

    const [error, setError] = useState('');
    const navigate = useNavigate();

    async function addPublication(e) {
        e.preventDefault();

        try {
            setError('');
            const publication = publicationRef.current.value;
            const authors = authorsRef.current.value;
            const publicationDate = publicationDateRef.current.value;
            const journal = journalRef.current.value;
            const abstract = abstractRef.current.value;
            const manuscriptLink = manuscriptLinkRef.current.value;

            await addDoc(collection(db, "publications"), {
                "publicationTitle": publication,
                "authors":authors,
                "publicationDate": publicationDate,
                "journal": journal,
                "abstract": abstract,
                "manuscriptLink": manuscriptLink,
                "serverTimestamp": serverTimestamp()
            });

            navigate('/allPublications');

        } catch (err) {
            setError('Failed to add the publication, reason: "' + err.message + '"')
        }
    }

    return (
        <div className='addPublication'>
            <Sidebar />
            <div className='addPublicationContainer'>
                <h2>Add Publication</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                <form onSubmit={addPublication}>
                    <div className='formInput'>
                        <label>Publication Title</label>
                        <input type='text' placeholder='Publication' ref={publicationRef} required />
                    </div>
                    <div className='formInput'>
                        <label>Authors</label>
                        <input type='text' placeholder='Author names' ref={authorsRef} required />
                    </div>
                    <div className='formInput'>
                        <label>Publication date</label>
                        <input type='date' placeholder='Publication date' ref={publicationDateRef} required></input>
                    </div>
                    <div className='formInput'>
                        <label>Journal/ Publisher</label>
                        <input type='text' placeholder='Journal Name, Publisher' ref={journalRef} required></input>
                    </div>
                    <div className='formInput'>
                        <label>Abstract</label>
                        <textarea type='text' placeholder='Abstract' ref={abstractRef} required rows='8'></textarea>
                    </div>
                    <div className='formInput'>
                        <label>PDF Link</label>
                        <input type='url' placeholder='Link to the official PDF version' ref={manuscriptLinkRef} required></input>
                    </div>
                    <button type='submit'>Add Publication</button>
                </form>
            </div>
        </div>
    )
}

export default AddPublication