import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../contexts/AuthContext';
import Sidebar from '../Sidebar';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

const ViewJob = () => {
  const location = useLocation();
  const jobId = location.state.id;

  const [jobTitle, setJobTitle] = useState([]);
  const [jobLocation, setJobLocation] = useState([]);
  const [jobResponsibilities, setJobResponsibilities] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [applicationDeadline, setApplicationDeadline] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [jobDuration, setJobDuration] = useState([]);
  const [applicationProcess, setApplicationProcess] = useState([]);
  const [payRange, setPayRange] = useState([]);

  const [error, setError] = useState([]);

  const viewJobHandler = async () => {
    try {
      setError('');
      const jobSnapshot = await getDoc(doc(db, "jobs", jobId));

      setJobTitle(jobSnapshot.data().jobTitle);
      setJobLocation(jobSnapshot.data().jobLocation);
      setJobResponsibilities(jobSnapshot.data().jobResponsibilities);
      setQualifications(jobSnapshot.data().qualifications);
      setApplicationDeadline(jobSnapshot.data().applicationDeadline);
      setStartDate(jobSnapshot.data().startDate);
      setJobDuration(jobSnapshot.data().jobDuration);
      setApplicationProcess(jobSnapshot.data().applicationProcess);
      setPayRange(jobSnapshot.data().payRange);
    } catch (err) {
      setError(err.message)
    }
  };

  useEffect(() => {
    if (jobId !== undefined && jobId !== '') {
      viewJobHandler();
    }
  }, [jobId]);

  return (
    <div className='viewJob'>
      <Sidebar />
      <div className='jobDetails'>
        <h2>Job Details</h2>
        {error && <Alert variant='danger'>{error}</Alert>}
        <div className='strong'>
          Job Title
        </div>
        <div className='content'>{jobTitle}</div>
        <div className='strong'>
          Job Location
        </div>
        <div className='content'>{jobLocation}</div>
        <div className='strong'>
          Job Responsibilities
        </div>
        <div className='content'>{jobResponsibilities}</div>
        <div className='strong'>
          Qualifications
        </div>
        <div className='content'>{qualifications}</div>
        <div className='strong'>
          Application Deadline
        </div>
        <div className='content'>{applicationDeadline}</div>
        <div className='strong'>
          Start Date
        </div>
        <div className='content'>{startDate}</div>
        <div className='strong'>
          Job Duration
        </div>
        <div className='content'>{jobDuration}</div>
        <div className='strong'>
          Application Process
        </div>
        <div className='content'>{applicationProcess}</div>
        <div className='strong'>
          Pay Range
        </div>
        <div className='content'>{payRange}</div>
        <div className='actionBtns'>
          <Link to='/allJobs' style={{ textDecoration: 'none' }}>
            <div className='backButton'>View all jobs</div>
          </Link>
          <Link to='/allJobs/editJob' state={{ id: jobId }} style={{ textDecoration: 'none' }}>
            <div className='updateButton'>Update job</div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ViewJob