import React, { useRef, useState } from 'react'
import { Card, Form, Button, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'

const Login = () => {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            navigate('/')
        } catch (err) {
            setError('Failed to log in, reason: "' + err.message + '"')
        }
        setLoading(false)
    }

    return (
        <>
            <section id='login'>
                <div className='container login'>
                    <div className='d-flex align-items-center justify-content-center mt-10'>
                        <div className='w-100'>
                            <Card>
                                <Card.Body>
                                    <h2 className='text-center mb-4'>Log In</h2>
                                    {error && <Alert variant='danger'>{error}</Alert>}
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group id='email'>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type='email' ref={emailRef} required autoFocus placeholder='example@email.com' />
                                        </Form.Group>
                                        <Form.Group id='password' className='mt-2'>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type='password' ref={passwordRef} required placeholder='*******' />
                                        </Form.Group>
                                        <Button disabled={loading} className='w-100 login-btn' type='submit'>Log In</Button>
                                    </Form>
                                    <div className='w-100 text-center mt-4'>
                                        <Link to='/forgotPassword'>Forgot Password?</Link>
                                    </div>
                                </Card.Body>
                            </Card>
                            <div className='w-100 text-center mt-2'>Don't have an account yet? Email the Admin to join.</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login