import React, { useRef, useState } from 'react'
import { Card, Form, Button, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link } from 'react-router-dom'

const ForgotPassword = () => {
    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setMessage('')
            setError('')
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setMessage('Please check your inbox for further instructions.')
        } catch (err) {
            setError('Failed to reset password, reason: "' + err.message + '"')
        }
        setLoading(false)
    }

    return (
        <>
            <section id='forgotPassword'>
                <div className='container forgotPassword'>
                    <div className='d-flex align-items-center justify-content-center mt-10'>
                        <div className='w-100'>
                            <Card>
                                <Card.Body>
                                    <h2 className='text-center mb-4'>Password Reset</h2>
                                    {error && <Alert variant='danger'>{error}</Alert>}
                                    {message && <Alert variant='success'>{message}</Alert>}
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group id='email'>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type='email' ref={emailRef} required autoFocus placeholder='example@email.com' />
                                        </Form.Group>
                                        <Button disabled={loading} className='w-100 reset-pwd-btn' type='submit'>Reset Password</Button>
                                    </Form>
                                    <div className='w-100 text-center mt-4'>
                                        <Link to='/login'>Log In</Link>
                                    </div>
                                </Card.Body>
                            </Card>
                            <div className='w-100 text-center mt-2'>Don't have an account yet? Email the Admin to join.</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ForgotPassword