import React from 'react'
import Sidebar from '../Sidebar'
import AllPublications from './AllPublications'

const Publications = () => {
    return (
        <div className='publications'>
            <Sidebar />
            <AllPublications />
        </div>
    )
}

export default Publications