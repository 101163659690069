import React, { useState, useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { db } from '../../contexts/AuthContext';
import { collection, onSnapshot} from 'firebase/firestore';

const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 0.65 },
    { field: 'email', headerName: 'Email', flex: 0.35 },
    { field: 'role', headerName: 'Role', flex: 0.3 },
];

const AllUsers = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        // Fetch data real-time
        const fetchData = onSnapshot(collection(db, "users"), (snapshot) => {
            setError('');
            let dataList = [];
            snapshot.docs.forEach((doc) => {
                dataList.push({ id: doc.id, ...doc.data() });
            });
            setData(dataList);
        },
            (error) => {
                setError(error);
            });

        return () => {
            fetchData();
        };
    }, [])

    return (
        <div className='allUsers'>
            <div className='usersTable'>
                <div className='usersTitle'>
                    All Users
                    <Link to='/allUsers/addUser' className='addUserBtn'>
                        <AddIcon className='materialIcon'></AddIcon>
                        Add new user
                    </Link>
                    {error && <Alert variant='danger'>{error}</Alert>}
                </div>
                <DataGrid autoHeight
                    getRowHeight={() => 'auto'}
                    rows={data}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'name', sort: 'asc' }],
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default AllUsers