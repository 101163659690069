import React from 'react'
import Sidebar from '../Sidebar'
import AllQueries from './AllQueries'

const Queries = () => {
    return (
        <div className='queries'>
            <Sidebar />
            <AllQueries />
        </div>
    )
}

export default Queries