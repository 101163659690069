import React, { useState, useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { db } from '../../contexts/AuthContext';
import { collection, deleteDoc, onSnapshot, doc } from 'firebase/firestore';

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Team Member',
        flex: 0.62,
        renderCell: (params) => {
            return (
                <div className="cellWithImg">
                    <img className="cellImg" src={params.row.profileImage} alt="userImage" />
                    {params.row.name}
                </div>
            );
        },
    },
    { field: 'organization', headerName: 'Organization', flex: 0.3 },
    { field: 'role', headerName: 'Role', flex: 0.3 },
    { field: 'linkedInURL', headerName: 'LinkedIn URL', flex: 0.65 },
];

const AllMembers = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch data real-time
        const fetchData = onSnapshot(collection(db, "members"), (snapshot) => {
            setError('');
            let dataList = [];
            snapshot.docs.forEach((doc) => {
                dataList.push({ id: doc.id, ...doc.data() });
            });
            setData(dataList);
        },
            (error) => {
                setError(error);
            });

        return () => {
            fetchData();
        };
    }, [])

    const deleteMember = async (id) => {
        try {
            setError('');
            await deleteDoc(doc(db, "members", id));
            setData(data.filter((item) => item.id !== id));
            navigate('/allMembers');
        } catch (err) {
            setError(err);
        }
    };

    const actionColumn = [{
        field: "action", headerName: "Action", flex: 0.5,
        renderCell: (params) => {
            const memberId = params.row.id;
            return (
                <div className='cellAction'>
                    {/* <Link to='/allMembers/viewMember' state={{ id: memberId }} style={{ textDecoration: "none" }}>
                        <div className='viewButton'>View details</div>
                    </Link> */}
                    <Link to={{ pathname: '/allMembers/editMember' }} state={{ id: memberId }} style={{ textDecoration: "none" }}>
                        <div className='updateButton'>Update</div>
                    </Link>
                    <Link to='/' style={{ textDecoration: "none" }}>
                        <div className='deleteButton'
                            onClick={() => deleteMember(memberId)}>
                            Delete
                        </div>
                    </Link>
                </div >
            )
        }
    }]

    return (
        <div className='allMembers'>
            <div className='membersTable'>
                <div className='membersTitle'>
                    All Members
                    <Link to='/allMembers/addMember' className='addMemberBtn'>
                        <AddIcon className='materialIcon'></AddIcon>
                        Add new member
                    </Link>
                    {error && <Alert variant='danger'>{error}</Alert>}
                </div>
                <DataGrid autoHeight
                    getRowHeight={() => 'auto'}
                    rows={data}
                    columns={columns.concat(actionColumn)}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'name', sort: 'asc' }],
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default AllMembers