import React, { useState, useRef, useEffect } from 'react'
import Sidebar from '../Sidebar'
import { Alert } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../contexts/AuthContext';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const EditMember = () => {
    const location = useLocation();
    const memberId = location.state.id;

    const nameRef = useRef('');
    const roleRef = useRef('');
    const orgRef = useRef('');
    const linkedInRef = useRef('');

    const [profileImage, setProfileImage] = useState([]);
    const [progress, setProgress] = useState([]);

    const [name, setName] = useState([]);
    const [role, setRole] = useState([]);
    const [org, setOrg] = useState([]);
    const [linkedIn, setLinkedIn] = useState([]);
    const [previewURL, setPreviewURL] = useState([]);

    const [error, setError] = useState(null);

    const navigate = useNavigate();

    // Update the database with new details from the form
    const editMember = async (e) => {
        e.preventDefault();

        setError('');

        try {
            if (memberId !== undefined && memberId !== '') {
                // If a member exists, update the details
                const name = nameRef.current.value;
                const role = roleRef.current.value;
                const organization = orgRef.current.value;
                const linkedInURL = linkedInRef.current.value;
                const profileImage = previewURL;

                const newMember = { name, role, organization, linkedInURL, profileImage };

                const memberDoc = doc(db, "members", memberId);
                await updateDoc(memberDoc, newMember);

                navigate('/allMembers');
            }
        } catch (err) {
            setError('Failed to update the member details, reason: "' + err.message + '"');
        }
    }

    // Populate the current details of publication in the form
    const editMemberHandler = async () => {
        try {
            setError('');

            const memberSnapshot = await getDoc(doc(db, "members", memberId));

            setName(memberSnapshot.data().name);
            setRole(memberSnapshot.data().role);
            setOrg(memberSnapshot.data().organization);
            setLinkedIn(memberSnapshot.data().linkedInURL);
            setPreviewURL(memberSnapshot.data().profileImage);
        } catch (err) {
            setError(err.message)
        }
    }

    useEffect(() => {
        if (memberId !== undefined && memberId !== '') {
            editMemberHandler();
        }

        const uploadProfileImage = () => {
            const name = new Date().getTime() + "-" + profileImage.name;

            console.log(name);
            const storageRef = ref(storage, name);
            const uploadTask = uploadBytesResumable(storageRef, profileImage);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const currentProgress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                    setProgress(currentProgress);
                    switch (snapshot.state) {
                        case "paused":
                            console.log("Upload is paused");
                            break;
                        case "running":
                            console.log("Upload is running");
                            break;
                        default:
                            break;
                    }
                },
                (err) => {
                    console.log(err);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // console.log(downloadURL);
                        setPreviewURL(downloadURL);
                    });
                }
            );
        };
        profileImage && uploadProfileImage();
    }, [memberId, profileImage]);

    return (
        <div className='editMember'>
            <Sidebar />
            <div className='editMemberContainer'>
                <h2>Update member details</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                {progress < 100 && <Alert variant='warning'>Please wait for the image to upload.</Alert>}
                {progress === 100 && <Alert variant='success'>Image uploaded. Please add the member.</Alert>}
                <form onSubmit={editMember}>
                    <div className='formInput'>
                        <label>Name</label>
                        <input type='text' placeholder='Full Name' ref={nameRef} required defaultValue={name} />
                    </div>
                    <div className='formInput'>
                        <label>Organization</label>
                        <input type='text' placeholder='Organization' ref={orgRef} required defaultValue={org}></input>
                    </div>
                    <div className='formInput'>
                        <label>LinkedIn URL</label>
                        <input type='url' placeholder='https://linkedin.com/in/username' ref={linkedInRef} required defaultValue={linkedIn}></input>
                    </div>
                    <div className='formInput'>
                        <label>Role</label>
                        <select id='role' ref={roleRef} required value={role} onChange={(e) => setRole(e.target.value)}>
                            <option value="" disabled selected>Select member role</option>
                            <option value="Masters' Student">Masters' Student</option>
                            <option value="PhD Student">PhD Student</option>
                            <option value="PostDoc Research Fellow">PostDoc Research Fellow</option>
                            <option value='Alumni'>Alumni</option>
                            <option value='Collaborator'>Collaborator</option>
                            <option value='Director'>Director</option>
                        </select>
                    </div>
                    <div className='formInput'>
                        <label>Change Profile Image</label>
                        <input type='file' id="profileImage" onChange={(event) => { setProfileImage(event.target.files[0]) }} accept='.jpg,.png,.jpeg'></input>
                    </div>
                    <button type='submit' disabled={progress !== null && progress < 100}>Update details</button>
                </form>
            </div>
        </div>
    )
}

export default EditMember