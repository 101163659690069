import React, { useState, useRef, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router';
import Sidebar from '../Sidebar'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db, storage } from '../../contexts/AuthContext';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const AddMember = () => {
    const nameRef = useRef('');
    const roleRef = useRef('');
    const orgRef = useRef('');
    const linkedInRef = useRef('');

    const [profileImage, setProfileImage] = useState([]);
    const [previewURL, setPreviewURL] = useState([]);
    const [progress, setProgress] = useState([]);

    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const uploadProfileImage = () => {
            const name = new Date().getTime() + "-" + profileImage.name;

            console.log(name);
            const storageRef = ref(storage, name);
            const uploadTask = uploadBytesResumable(storageRef, profileImage);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const currentProgress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                    setProgress(currentProgress);
                    switch (snapshot.state) {
                        case "paused":
                            console.log("Upload is paused");
                            break;
                        case "running":
                            console.log("Upload is running");
                            break;
                        default:
                            break;
                    }
                },
                (err) => {
                    console.log(err);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // console.log(downloadURL);
                        setPreviewURL(downloadURL);
                    });
                }
            );
        };
        profileImage && uploadProfileImage();
    }, [profileImage]);

    async function addMember(e) {
        e.preventDefault();

        try {
            setError('');
            const name = nameRef.current.value;
            const role = roleRef.current.value;
            const organization = orgRef.current.value;
            const linkedInURL = linkedInRef.current.value;

            await addDoc(collection(db, "members"), {
                "name": name,
                "role": role,
                "organization": organization,
                "linkedInURL": linkedInURL,
                "profileImage": previewURL,
                "serverTimestamp": serverTimestamp()
            });

            navigate('/allMembers');

        } catch (err) {
            setError('Failed to add the team member, reason: "' + err.message + '"')
        }
    }

    return (
        <div className='addMember'>
            <Sidebar />
            <div className='addMemberContainer'>
                <h2>Add Member</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                {progress < 100 && <Alert variant='warning'>Please wait for the image to upload.</Alert>}
                {progress === 100 && <Alert variant='success'>Image uploaded! Please add the member.</Alert>}
                <form onSubmit={addMember}>
                    <div className='formInput'>
                        <label>Name</label>
                        <input type='text' placeholder='Full Name' ref={nameRef} required />
                    </div>
                    <div className='formInput'>
                        <label>Organization</label>
                        <input type='text' placeholder='Organization' ref={orgRef} required></input>
                    </div>
                    <div className='formInput'>
                        <label>LinkedIn URL</label>
                        <input type='url' placeholder='https://linkedin.com/in/username' ref={linkedInRef} required></input>
                    </div>
                    <div className='formInput'>
                        <label>Role</label>
                        <select id='role' ref={roleRef} required>
                            <option value="" disabled selected>Select member role</option>
                            <option value="Masters' Student">Masters' Student</option>
                            <option value="PhD Student">PhD Student</option>
                            <option value="PostDoc Research Fellow">PostDoc Research Fellow</option>
                            <option value='Alumni'>Alumni</option>
                            <option value='Collaborator'>Collaborator</option>
                            <option value='Director'>Director</option>
                        </select>
                    </div>
                    <div className='formInput'>
                        <label>Profile Image</label>
                        <input type='file' required id="profileImage" onChange={(event) => { setProfileImage(event.target.files[0]) }} accept='.jpg,.png,.jpeg'></input>
                    </div>
                    <button type='submit' disabled={progress !== null && progress < 100}>Add Member</button>
                </form>
            </div>
        </div>
    )
}

export default AddMember