import React, { useState, useRef, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';


const EditHighlight = () => {
    const location = useLocation();
    const highlightId = location.state.id;

    // setHighlightId();

    const [highlight, setHighlight] = useState('');
    const [highlightDate, setHighlightDate] = useState('');
    const [highlightLink, setHighlightLink] = useState('');

    const highlightRef = useRef('');
    const highlightDateRef = useRef('');
    const highlightLinkRef = useRef('');

    const [error, setError] = useState('');

    const navigate = useNavigate();

    // Update the database with new details from the form
    const editHighlight = async (e) => {
        e.preventDefault();

        setError('');

        try {
            if (highlightId !== undefined && highlightId !== '') {
                // If a highlight exists, update the details
                const highlight = highlightRef.current.value;
                const highlightDate = highlightDateRef.current.value;
                const highlightLink = highlightLinkRef.current.value;

                const highlightDoc = doc(db, "highlights", highlightId);
                await updateDoc(highlightDoc, { highlight, highlightDate, highlightLink });

                navigate('/allHighlights');
            }
        } catch (err) {
            setError('Failed to update the highlight, reason: "' + err.message + '"');
        }
    };

    // Populate the current details of highlight in the form
    const editHighlightHandler = async () => {
        try {
            setError('');
            const highlightSnapshot = await getDoc(doc(db, "highlights", highlightId));

            setHighlight(highlightSnapshot.data().highlight);
            setHighlightDate(highlightSnapshot.data().highlightDate);
            setHighlightLink(highlightSnapshot.data().highlightLink);
        } catch (err) {
            setError(err.message)
        }
    };

    useEffect(() => {
        if (highlightId !== undefined && highlightId !== '') {
            editHighlightHandler();
        }
    }, [highlightId]);

    return (
        <div className='editHighlight'>
            <Sidebar />
            <div className='editHighlightContainer'>
                <h2>Update Highlight</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                {/* {success && <Alert variant='success'>{success}</Alert>} */}
                <form onSubmit={editHighlight}>
                    <div className='formInput'>
                        <label>Highlight</label>
                        <textarea placeholder='Highlight' ref={highlightRef} required rows='3' defaultValue={highlight}></textarea>
                    </div>
                    <div className='formInput'>
                        <label>Date</label>
                        <input type='date' placeholder='Date' min={highlightDate} ref={highlightDateRef} required defaultValue={highlightDate}></input>
                    </div>
                    <div className='formInput'>
                        <label>URL Link</label>
                        <input type='link' placeholder='Supporting Link' ref={highlightLinkRef} defaultValue={highlightLink}></input>
                    </div>
                    <button type='submit'>Update Highlight</button>
                </form>
            </div>
        </div>
    )
}

export default EditHighlight