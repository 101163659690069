import React, { useRef, useState, useEffect } from 'react'
import { Card, Form, Button, Alert } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Sidebar from '../Sidebar'
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../contexts/AuthContext';

const UpdateProfile = () => {
    const location = useLocation();
    const userId = location.state.uid;

    const [name, setName] = useState([]);
    const [email, setEmail] = useState([]);

    const nameRef = useRef('');
    const emailRef = useRef('');
    const passwordRef = useRef('');
    const confirmPasswordRef = useRef('');
    const { currentUser, updateEmail, updatePassword } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function editUserProfile(e) {
        e.preventDefault()

        if (passwordRef.current.value !== confirmPasswordRef.current.value) {
            return setError('Passwords do not match')
        }
        const promises = []
        setError('');
        setLoading(true);
        if (emailRef.current.value !== currentUser.email) {
            promises.push(updateEmail(emailRef.current.value));
        }

        if (passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value));
        }

        try {
            if (userId !== undefined && userId !== '') {
                // If a user exists, update the details

                const name = nameRef.current.value;
                const email = emailRef.current.value;

                const newUser = { name, email };

                const userDoc = doc(db, "users", userId);

                const updateUser = updateDoc(userDoc, newUser);
                promises.push(updateUser);
            }
        } catch (err) {
            setError('Failed to update the user profile, reason: "' + err.message + '"');
        }

        Promise.all(promises).then(() => {
            navigate('/profile')
        }).catch((err) => {
            setError('Failed to update account, reason: "' + err.message + '"')
        }).finally(() => {
            setLoading(false)
        })

    }

    // Populate the current details of user in the form
    const editUserHandler = async () => {
        try {
            setError('');

            const userSnapshot = await getDoc(doc(db, "users", userId));

            setName(userSnapshot.data().name);
            setEmail(userSnapshot.data().email);
        } catch (err) {
            setError(err.message)
        }
    };

    useEffect(() => {
        if (userId !== undefined && userId !== '') {
            editUserHandler();
        }
    }, [userId]);

    return (
        <>
            <div className='updateProfile'>
                <Sidebar />
                <div className='d-flex align-items-center justify-content-center mt-10 updateProfileContainer'>
                    <div className='w-100'>
                        <Card>
                            <Card.Body>
                                <h2 className='mb-4'>Update Profile</h2>
                                {error && <Alert variant='danger'>{error}</Alert>}
                                <Form onSubmit={editUserProfile}>
                                    <Form.Group id='name'>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type='text' ref={nameRef} required defaultValue={name} />
                                    </Form.Group>
                                    <Form.Group id='email'>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type='email' ref={emailRef} required defaultValue={email} />
                                    </Form.Group>
                                    <Form.Group id='password' className='mt-2'>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type='password' ref={passwordRef} placeholder='Leave blank to keep the same password' />
                                    </Form.Group>
                                    <Form.Group id='confirmPassword' className='mt-2'>
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type='password' ref={confirmPasswordRef} placeholder='Leave blank to keep the same password' />
                                    </Form.Group>
                                    <Button disabled={loading} className='updateProfileBtn' type='submit'>Update Profile</Button>
                                    <Link className='cancelUpdateBtn' to='/profile'>Cancel</Link>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateProfile