import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./components/Dashboard";
import Login from './components/Login';
import ForgotPassword from "./components/ForgotPassword";
import Highlights from "./components/highlights/Highlights";
import EditHighlight from "./components/highlights/EditHighlight";
import AddHighlight from "./components/highlights/AddHighlight";
import Publications from "./components/publications/Publications";
import AddPublication from "./components/publications/AddPublication";
import EditPublication from "./components/publications/EditPublication";
import ViewPublication from "./components/publications/ViewPublication";
import Members from './components/members/Members';
import EditMember from './components/members/EditMember';
import AddMember from './components/members/AddMember';
import Jobs from './components/jobs/Jobs';
import AddJob from './components/jobs/AddJob';
import ViewJob from './components/jobs/ViewJob';
import EditJob from './components/jobs/EditJob';
import Users from './components/users/Users';
import AddUser from './components/users/AddUser';
import Profile from './components/profile/Profile';
import UpdateProfile from "./components/profile/UpdateProfile";
import AllQueries from "./components/queries/Queries";
import Error from "./components/Error";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route exact path='/' element={<PrivateRoute />}>
            <Route exact path='/' element={<Dashboard />} />
          </Route>
          <Route exact path='/dashboard' element={<PrivateRoute />}>
            <Route exact path='/dashboard' element={<Dashboard />} />
          </Route>
          <Route exact path='/allHighlights' element={<PrivateRoute />}>
            <Route exact path='/allHighlights' element={<Highlights />} />
            <Route path="editHighlight" element={<EditHighlight />} />
            <Route path="addHighlight" element={<AddHighlight />} />
          </Route>
          <Route exact path='/allPublications' element={<PrivateRoute />}>
            <Route exact path='/allPublications' element={<Publications />} />
            <Route path="viewPublication" element={<ViewPublication />} />
            <Route path="editPublication" element={<EditPublication />} />
            <Route path="addPublication" element={<AddPublication />} />
          </Route>
          <Route exact path='/allMembers' element={<PrivateRoute />}>
            <Route exact path='/allMembers' element={<Members />} />
            <Route path="editMember" element={<EditMember />} />
            <Route path="addMember" element={<AddMember />} />
          </Route>
          <Route exact path='/allJobs' element={<PrivateRoute />}>
            <Route exact path='/allJobs' element={<Jobs />} />
            <Route path="viewJob" element={<ViewJob />} />
            <Route path="editJob" element={<EditJob />} />
            <Route path="addJob" element={<AddJob />} />
          </Route>
          <Route exact path='/allUsers' element={<PrivateRoute />}>
            <Route exact path='/allUsers' element={<Users />} />
            <Route path="addUser" element={<AddUser />} />
          </Route>
          <Route exact path='/profile' element={<PrivateRoute />}>
            <Route path='/profile' element={<Profile />} />
            <Route path='updateProfile' element={<UpdateProfile />} />
          </Route>
          <Route exact path='/allQueries' element={<PrivateRoute />}>
            <Route path='/allQueries' element={<AllQueries />} />
          </Route>
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/forgotPassword' element={<ForgotPassword />} />
          <Route exact={true} path='*' element={<Error />}></Route>
        </Routes>
      </AuthProvider>
    </Router>
  )
}

export default App;
