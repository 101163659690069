import React from 'react'
import Sidebar from '../Sidebar'
import AllHighlights from './allHighlights'

const Highlights = () => {
    return (
        <div className='highlights'>
            <Sidebar />
            <AllHighlights />
        </div>
    )
}

export default Highlights