import React, { useState, useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Alert } from 'react-bootstrap'
import { db } from '../../contexts/AuthContext';
import { collection, onSnapshot } from 'firebase/firestore';

const columns: GridColDef[] = [
    { field: 'fullName', headerName: 'Name', flex: 0.45 },
    { field: 'email', headerName: 'Email', flex: 0.35 },
    { field: 'message', headerName: 'Query', flex: 0.65 },
    { field: 'timeStamp', headerName: 'Date Submitted', flex: 0.65 },
];

const AllQueries = () => {

    const [data, setData] = useState([]);
    const [error, setError] = useState([]);

    useEffect(() => {
        // Fetch data real-time
        const fetchData = onSnapshot(collection(db, "queries"), (snapshot) => {
            setError('');
            let dataList = [];
            snapshot.docs.forEach((doc) => {
                dataList.push({ id: doc.id, ...doc.data() });
            });
            // console.log(dataList);
            setData(dataList);
        },
            (error) => {
                setError(error);
            });

        return () => {
            fetchData();
        };
    }, [])

    // const markQueryRead = async (id) => {
    //     try {
    //         setError('');
    //         const queryDocRef = doc(db, "queries", id);
    //         await updateDoc(queryDocRef, {
    //             status: "Read"
    //         })
    //     } catch (err) {
    //         setError(err);
    //     }
    // }

    // const actionColumn = [{
    //     field: "action", headerName: "Action", flex: 0.5,
    //     renderCell: (params) => {
    //         const queryId = params.row.id;
    //         return (
    //             <div className='cellAction'>
    //                 <div className='updateButton' onClick={() => { markQueryRead(queryId);  }}>Mark as Read</div>
    //             </div >
    //         )
    //     }
    // }]

    return (
        <div className='allQueries'>
            <div className='queriesTable'>
                <div className='queriesTitle'>
                    All Queries
                    {error && <Alert variant='danger'>{error}</Alert>}
                </div>
                <DataGrid autoHeight
                    getRowHeight={() => 'auto'}
                    rows={data}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'timeStamp', sort: 'desc' }],
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default AllQueries