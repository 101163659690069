import React, { useState, useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { db } from '../../contexts/AuthContext';
import { collection, deleteDoc, onSnapshot, doc } from 'firebase/firestore';

const columns: GridColDef[] = [
    { field: 'jobTitle', headerName: 'Job Title', flex: 0.3 },
    { field: 'jobLocation', headerName: 'Location', flex: 0.3 },
    { field: 'applicationDeadline', headerName: 'Application Deadline', flex: 0.3 },
    { field: 'startDate', headerName: 'Start Date', flex: 0.3 },
    { field: 'jobDuration', headerName: 'Job Duration', flex: 0.3 },
    { field: 'payRange', headerName: 'Pay Range (hourly)', flex: 0.3 }
];

const AllJobs = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch data real-time
        const fetchData = onSnapshot(collection(db, "jobs"), (snapshot) => {
            setError('');
            let dataList = [];
            snapshot.docs.forEach((doc) => {
                dataList.push({ id: doc.id, ...doc.data() });
            });
            setData(dataList);
        },
            (error) => {
                setError(error);
            });

        return () => {
            fetchData();
        };
    }, [])

    // Delete job
    const deleteJob = async (id) => {
        try {
            setError('');
            await deleteDoc(doc(db, "jobs", id));
            setData(data.filter((item) => item.id !== id));
            navigate('/allJobs');
        } catch (err) {
            setError(err);
        }
    };

    const actionColumn = [{
        field: "action", headerName: "Action", flex: 0.5,
        renderCell: (params) => {
            const jobId = params.row.id;
            return (
                <div className='cellAction'>
                    <Link to='/allJobs/viewJob' state={{ id: jobId }} style={{ textDecoration: "none" }}>
                        <div className='viewButton'>View details</div>
                    </Link>
                    <Link to={{ pathname: '/allJobs/editJob' }} state={{ id: jobId }} style={{ textDecoration: "none" }}>
                        <div className='updateButton'>Update</div>
                    </Link>
                    <Link to='/' style={{ textDecoration: "none" }}>
                        <div className='deleteButton'
                            onClick={() => deleteJob(jobId)}>
                            Delete
                        </div>
                    </Link>
                </div >
            )
        }
    }]

    return (
        <div className='allJobs'>
            <div className='jobsTable'>
                <div className='jobsTitle'>
                    All Jobs
                    <Link to='/allJobs/addJob' className='addJobBtn'>
                        <AddIcon className='materialIcon'></AddIcon>
                        Add new job
                    </Link>
                    {error && <Alert variant='danger'>{error}</Alert>}
                </div>
                <DataGrid autoHeight
                    getRowHeight={() => 'auto'}
                    rows={data}
                    columns={columns.concat(actionColumn)}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'startDate', sort: 'asc' }],
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default AllJobs