import React, { useState, useRef } from 'react'
import { Alert } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import { setDoc, doc } from 'firebase/firestore';
import { useAuth, db } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const AddUser = () => {
  const nameRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const confirmPasswordRef = useRef()
  const { signUp } = useAuth()

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  async function addUser(e) {
    e.preventDefault()

    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      return setError('Passwords do not match')
    }

    try {
      setError('')
      setLoading(true)
      const res = await signUp(emailRef.current.value, passwordRef.current.value);
      const name = nameRef.current.value;
      const email = emailRef.current.value;

      await setDoc(doc(db, "users", res.user.uid), {
        name: name,
        authProvider: "email-password",
        email: email,
        role: "Admin"
      })
      navigate('/allUsers');
    } catch (err) {
      setError('Failed to create an account, reason: "' + err.message + '"')
    }
    setLoading(false)
  }

  return (
    <div className='addUser'>
      <Sidebar />
      <div className='addUserContainer'>
        <h2>Add Publication</h2>
        {error && <Alert variant='danger'>{error}</Alert>}
        <form onSubmit={addUser}>
          <div className='formInput'>
            <label>Full Name</label>
            <input type='text' placeholder='Full Name' ref={nameRef} required />
          </div>
          <div className='formInput'>
            <label>Email</label>
            <input type='email' placeholder='e.g. example@email.com' ref={emailRef} required />
          </div>
          <div className='formInput'>
            <label>Password</label>
            <input type='password' placeholder='Atleast six characters with numbers and symbols' ref={passwordRef} required />
          </div>
          <div className='formInput'>
            <label>Confirm Password</label>
            <input type='password' placeholder='Should match the password field above' ref={confirmPasswordRef} required />
          </div>
          <button disabled={loading} type='submit'>Add User</button>
        </form>
      </div>
    </div>
  )
}

export default AddUser