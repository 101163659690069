import React from 'react'
import Sidebar from '../Sidebar'
import AllJobs from './AllJobs'

const Jobs = () => {
    return (
        <div className='jobs'>
            <Sidebar />
            <AllJobs />
        </div>
    )
}

export default Jobs