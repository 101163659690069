import React, { useState, useRef } from 'react'
import { Alert } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const todayDate = new Date();
const todayLocalDate = (new Date(todayDate.getTime() - todayDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

const AddHighlight = () => {
    const highlightRef = useRef('');
    const highlightDateRef = useRef('');
    const highlightLinkRef = useRef('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    // const [success, setSuccess] = useState('');

    async function addHighlight(e) {
        e.preventDefault();

        try {
            setError('');
            const highlight = highlightRef.current.value;
            const highlightDate = highlightDateRef.current.value;
            const highlightLink = highlightLinkRef.current.value;
            await addDoc(collection(db, "highlights"), {
                "highlight": highlight,
                "highlightDate": highlightDate,
                "highlightLink": highlightLink,
                "serverTimestamp": serverTimestamp()
            });
            navigate('/allHighlights');
        } catch (err) {
            setError('Failed to add the highlight, reason: "' + err.message + '"')
        }
    }

    return (
        <div className='addHighlight'>
            <Sidebar />
            <div className='addHighlightContainer'>
                <h2>Add Highlight</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                {/* {success && <Alert variant='success'>{success}</Alert>} */}
                <form onSubmit={addHighlight}>
                    <div className='formInput'>
                        <label>Highlight</label>
                        <textarea placeholder='Highlight' ref={highlightRef} required rows='3'></textarea>
                    </div>
                    <div className='formInput'>
                        <label>Date</label>
                        <input type='date' placeholder='Date' min={todayLocalDate} ref={highlightDateRef} required></input>
                    </div>
                    <div className='formInput'>
                        <label>URL Link</label>
                        <input type='link' placeholder='Supporting Link' ref={highlightLinkRef}></input>
                    </div>
                    <button type='submit'>Add Highlight</button>
                </form>
            </div>
        </div>
    )
}

export default AddHighlight