import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../Sidebar'
import { Alert } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../contexts/AuthContext';

const EditJob = () => {
  const location = useLocation();
  const jobId = location.state.id;

  const [jobTitle, setJobTitle] = useState([]);
  const [jobLocation, setJobLocation] = useState([]);
  const [jobResponsibilities, setJobResponsibilities] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [applicationDeadline, setApplicationDeadline] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [jobDuration, setJobDuration] = useState([]);
  const [applicationProcess, setApplicationProcess] = useState([]);
  const [payRange, setPayRange] = useState([]);

  const jobTitleRef = useRef('');
  const jobLocationRef = useRef('');
  const jobResponsibilitiesRef = useRef('');
  const qualificationsRef = useRef('');
  const startDateRef = useRef('');
  const jobDurationRef = useRef('');
  const applicationProcessRef = useRef('');
  const applicationDeadlineRef = useRef('');
  const payRangeRef = useRef('');

  const [error, setError] = useState([]);

  const navigate = useNavigate();

  // Update the database with new details from the form
  const editJob = async (e) => {
    e.preventDefault();

    setError('');

    try {
      if (jobId !== undefined && jobId !== '') {
        // If a publication exists, update the details
        const jobTitle = jobTitleRef.current.value;
        const jobLocation = jobLocationRef.current.value;
        const jobResponsibilities = jobResponsibilitiesRef.current.value;
        const qualifications = qualificationsRef.current.value;
        const startDate = startDateRef.current.value;
        const jobDuration = jobDurationRef.current.value;
        const applicationDeadline = applicationDeadlineRef.current.value;
        const applicationProcess = applicationProcessRef.current.value;
        const payRange = payRangeRef.current.value;

        const newJob = { jobTitle, jobLocation, jobResponsibilities, qualifications, startDate, jobDuration, applicationDeadline, applicationProcess, payRange };

        const jobDoc = doc(db, "jobs", jobId);
        await updateDoc(jobDoc, newJob);

        navigate('/allJobs');
      }
    } catch (err) {
      setError('Failed to update the highlight, reason: "' + err.message + '"');
    }
  };

  // Populate the current details of publication in the form
  const editJobHandler = async () => {
    try {
      setError('');

      const jobSnapshot = await getDoc(doc(db, "jobs", jobId));

      setJobTitle(jobSnapshot.data().jobTitle);
      setJobLocation(jobSnapshot.data().jobLocation);
      setJobResponsibilities(jobSnapshot.data().jobResponsibilities);
      setQualifications(jobSnapshot.data().qualifications);
      setApplicationDeadline(jobSnapshot.data().applicationDeadline);
      setStartDate(jobSnapshot.data().startDate);
      setJobDuration(jobSnapshot.data().jobDuration);
      setApplicationProcess(jobSnapshot.data().applicationProcess);
      setPayRange(jobSnapshot.data().payRange);
    } catch (err) {
      setError(err.message)
    }
  };

  useEffect(() => {
    if (jobId !== undefined && jobId !== '') {
      editJobHandler();
    }
  }, [jobId]);

  return (
    <div className='editJob'>
      <Sidebar />
      <div className='editJobContainer'>
        <h2>Update Job</h2>
        {error && <Alert variant='danger'>{error}</Alert>}
        <form onSubmit={editJob}>
          <div className='formInput'>
            <label>Job Title</label>
            <input type='text' placeholder='Research Assistant' ref={jobTitleRef} defaultValue={jobTitle} required />
          </div>
          <div className='formInput'>
            <label>Location</label>
            <input type='text' placeholder='DaTALab, York University' ref={jobLocationRef} defaultValue={jobLocation} required></input>
          </div>
          <div className='formInput'>
            <label>Job Responsibilities</label>
            <textarea placeholder='...' ref={jobResponsibilitiesRef} defaultValue={jobResponsibilities} required rows="8"></textarea>
          </div>
          <div className='formInput'>
            <label>Qualifications</label>
            <textarea placeholder='...' ref={qualificationsRef} defaultValue={qualifications} required rows="8"></textarea>
          </div>
          <div className='formInput'>
            <label>Application Deadline</label>
            <input type='date' placeholder='Date' min={applicationDeadline} defaultValue={applicationDeadline} ref={applicationDeadlineRef} required></input>
          </div>
          <div className='formInput'>
            <label>Tentative Start Date</label>
            <input type='date' placeholder='Date' min={startDate} defaultValue={startDate} ref={startDateRef} required></input>
          </div>
          <div className='formInput'>
            <label>Job Duration</label>
            <input type='text' placeholder='3 months' ref={jobDurationRef} defaultValue={jobDuration} required></input>
          </div>
          <div className='formInput'>
            <label>Hourly Pay Range</label>
            <input type='number' placeholder='C$35' ref={payRangeRef} defaultValue={payRange}></input>
          </div>
          <div className='formInput'>
            <label>Application Process</label>
            <input type='text' placeholder='Email your CV to vmago@lakeheadu.ca' ref={applicationProcessRef} defaultValue={applicationProcess} required></input>
          </div>
          <button type='submit'>Update Job</button>
        </form>
      </div>
    </div>
  )
}

export default EditJob