import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../contexts/AuthContext';
import Sidebar from '../Sidebar';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

const ViewPublication = () => {
    const location = useLocation();
    const publicationId = location.state.id;

    const [error, setError] = useState([]);

    const [title, setTitle] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [publicationDate, setPublicationDate] = useState([]);
    const [journal, setJournal] = useState([]);
    const [abstract, setAbstract] = useState([]);
    const [link, setLink] = useState([]);

    const viewPublicationHandler = async () => {
        try {
            setError('');
            const publicationSnapshot = await getDoc(doc(db, "publications", publicationId));

            setTitle(publicationSnapshot.data().publicationTitle);
            setAuthors(publicationSnapshot.data().authors);
            setPublicationDate(publicationSnapshot.data().publicationDate);
            setJournal(publicationSnapshot.data().journal);
            setAbstract(publicationSnapshot.data().abstract);
            setLink(publicationSnapshot.data().manuscriptLink);
        } catch (err) {
            setError(err.message)
        }
    };

    useEffect(() => {
        if (publicationId !== undefined && publicationId !== '') {
            viewPublicationHandler();
        }
    }, [publicationId]);

    return (
        <div className='viewPublication'>
            <Sidebar />
            <div className='publicationDetails'>
                <h2>Publication Details</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                <div className='strong'>
                    Publication Title
                </div>
                <div className='content'>{title}</div>
                <div className='strong'>
                    Authors
                </div>
                <div className='content'>{authors}</div>
                <div className='strong'>
                    Publication Date
                </div>
                <div className='content'>{publicationDate}</div>
                <div className='strong'>
                    Journal
                </div>
                <div className='content'>{journal}</div>
                <div className='strong'>
                    Abstract
                </div>
                <div className='content'>{abstract}</div>
                <div className='strong'>
                    PDF Link
                </div>
                <div className='content'>
                    <a href={link} target='_blank'>Click here</a>
                </div>
                <div className='actionBtns'>
                    <Link to='/allPublications' style={{ textDecoration: 'none' }}>
                        <div className='backButton'>View all publications</div>
                    </Link>
                    <Link to='/allPublications/editPublication' state={{ id: publicationId }} style={{ textDecoration: 'none' }}>
                        <div className='updateButton'>Update publication</div>
                    </Link>
                </div>
            </div>
        </div >
    )
}

export default ViewPublication